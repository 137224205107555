import Navbar, { NavbarProps } from "./Navbar";
import "../styles/BornToBeach.css";
import brauunion from "../assets/logos/brauunion.png";
import redbull from "../../public/partner_logos/redBull.png";
import skruf from "../../public/partner_logos/skruf.png";
import btbImage from "../assets/logos/btb.png";
import Footer from "./Footer";
import { EventData, WEBSITE_URL } from "./Routes";
import EventDisplay from "./EventDisplay";
import { EventType } from "./EventFormItem";
import Feed from "react-instagram-authless-feed";
import SingleNavbar from "./SingleNavbar";
import btcImage from "../assets/logos/btb-club.png";
import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

export interface BornToBeachProps {
  events?: EventData[];
  partnersOfEvent: Map<string, string[]>;
  logo: string;
}

export default function BornToBeach({
  events,
  partnersOfEvent,
  logo,
}: BornToBeachProps) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [futureEvents, setFutureEvents] = useState<EventData[]>([]);
  const [loadFinished, setLoadFinished] = useState(false);

  useEffect(() => {
    axios
      .get(WEBSITE_URL + "future-events")
      .then((response) => {
        setFutureEvents(response.data);
        setLoadFinished(true);
      })
      .catch((err) => console.log(err));
  }, []);

  const navigate = useNavigate();
  return (
    <div className={"background"}>
      <SingleNavbar
        selectedTab={"none"}
        imageUrl={logo}
        extended={isNavExpanded}
        onExtendedChange={(val) => setIsNavExpanded(val)}
      ></SingleNavbar>
      {!isNavExpanded && (
        <div className={"card bornToClubMain columnFlex"}>
          <div className={"splitter"}>
            <div className={"split hover"} onClick={() => navigate("/events")}>
              <div className={"cardHeader"}>
                <Link className={"subLink"} to={"/events"}>
                  Events
                </Link>
              </div>
              <div className={"mainSubheading"}>
                <>
                  {(!loadFinished ||
                    (loadFinished && futureEvents.length > 0)) && (
                    <>{"Upcoming Events"}</>
                  )}
                  {loadFinished && futureEvents.length === 0 && (
                    <>Hier geht's zu allen Events</>
                  )}
                </>
              </div>
              <EventDisplay
                events={futureEvents}
                type={EventType.event}
                size={"full"}
                eventTime={"future"}
                partnersOfEvent={partnersOfEvent}
                loadingFinished={loadFinished}
                imageIfNone={"startbild.jpg"}
              />
            </div>
            <div className={"splitLine"}></div>
            <div
              className={"split hover"}
              onClick={() => navigate("/beachClub")}
            >
              <div className={"cardHeader"}>
                <Link className={"subLink"} to={"/beachClub"}>
                  Beach Club
                </Link>
              </div>
              <div className={"blockInfo"}>
                DER Beach Club am Neusiedler See - Restaurant, Bar & Lounge
              </div>
              <div className={"eventWrapper"}>
                <img
                  src={"/clubContent/bc_job_website.jpg"}
                  style={{ width: "90%", maxHeight: "700px" }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer footerTab={"none"} />
    </div>
  );
}
