import "../styles/BornToBeach.css";
import { EventData, WEBSITE_URL } from "./Routes";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { DateTime } from "luxon";

import QRCode from "qrcode.react";

export interface SendQRCodesProps {
  logo: string;
  event?: EventData;
}

export interface MailNamePair {
  email: string;
  name: string;
  count: number;
  category: string;
}

export interface QrCodeData {
  id: string;
  email: string;
  name: string;
  count: number;
  ticketsLeft: number;
  category: string;
  timestamp: string;
  ticketsSent: number;
  event: string;
}

export default function SendQRCodes({ logo, event }: SendQRCodesProps) {
  const [emailsWithNames, setEmailsWithNames] = useState<MailNamePair[]>([]);
  const [newMail, setNewMail] = useState<string>("");
  const [newName, setNewName] = useState<string>("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [resendColumn, setResendColumn] = useState<QrCodeData | undefined>(
    undefined
  );
  const [resendMail, setResendMail] = useState<string>("");

  const [newCount, setNewCount] = useState<number>(1);
  const [newCategory, setNewCategory] = useState<string>("");
  const [qrData, setQrData] = useState("");

  const [currentQrCodes, setCurrentQrCodes] = useState<QrCodeData[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const emailList = useMemo(() => {
    let emailList: MailNamePair[] = [];
    for (let email of emailsWithNames) {
      emailList.push(email);
    }
    return emailList;
  }, [emailsWithNames]);

  const removeMail = (value: string) => {
    let newMailsWithNames: MailNamePair[] = [];
    for (let emailWithName of emailsWithNames) {
      if (emailWithName.email !== value) {
        newMailsWithNames.push(emailWithName);
      }
    }
    setEmailsWithNames(newMailsWithNames);
  };

  const sendQRCodes = () => {
    setIsSubmitting(true);
    console.log(emailList);
    if (event?.id === "sunside_2025") {
      axios
        .post(WEBSITE_URL + "send-qr-email-festival", {
          emailList: emailList,
          eventId: event?.id,
          eventName: event?.name,
        })
        .then(() => {
          setIsSubmitting(false);
          alert("Emails wurden erfolgreich versendet!");
          setEmailsWithNames([]);
        })
        .catch((error) => {
          alert("Error occured! " + error);
          setIsSubmitting(false);
        });
    } else {
      axios
        .post(WEBSITE_URL + "send-qr-email", {
          emailList: emailList,
          eventId: event?.id,
          eventName: event?.name,
        })
        .then(() => {
          setIsSubmitting(false);
          alert("Emails wurden erfolgreich versendet!");
          setEmailsWithNames([]);
        })
        .catch((error) => {
          alert("Error occured! " + error);
          setIsSubmitting(false);
        });
    }
  };

  const resendQrCode = () => {
    if (resendColumn !== undefined) {
      const pair: MailNamePair = {
        name: resendColumn.name,
        category: resendColumn.category,
        count: resendColumn.ticketsSent,
        email: resendMail,
      };
      setIsSubmitting(true);
      const nameMailPair: MailNamePair[] = [pair];
      if (event?.id === "sunside_2025") {
        axios
          .post(WEBSITE_URL + "resend-qr-email-festival/" + resendColumn.id, {
            emailList: nameMailPair,
            eventId: event?.id,
            eventName: event?.name,
          })
          .then(() => {
            setIsSubmitting(false);
            alert("Emails wurden erfolgreich versendet!");
            setResendColumn(undefined);
            setResendMail("");
            setDisplayDialog(false);
          })
          .catch(() => {
            alert("Error occured!");
            setIsSubmitting(false);
          });
      } else {
        axios
          .post(WEBSITE_URL + "resend-qr-email/" + resendColumn.id, {
            emailList: nameMailPair,
            eventId: event?.id,
            eventName: event?.name,
          })
          .then(() => {
            setIsSubmitting(false);
            alert("Emails wurden erfolgreich versendet!");
            setResendColumn(undefined);
            setResendMail("");
            setDisplayDialog(false);
          })
          .catch(() => {
            alert("Error occured!");
            setIsSubmitting(false);
          });
      }
    }
  };

  useEffect(() => {
    axios
      .get(WEBSITE_URL + "qr-codes")
      .then((response) => {
        let data: QrCodeData[] = response.data;
        let sortedData = data.slice().sort((a: QrCodeData, b: QrCodeData) => {
          if (a.timestamp < b.timestamp) return 1;
          if (a.timestamp > b.timestamp) return -1;
          return 0;
        });
        sortedData = sortedData.filter((item) => item.event === event?.id);
        setCurrentQrCodes(sortedData);
      })
      .catch((err) => console.log(err));
  }, [event?.id]);

  const downloadQr = useCallback((id: string) => {
    setQrData(id);
  }, []);

  useEffect(() => {
    if (qrData) {
      // State has been updated, proceed with downloading QR code
      const qrCodeURL = (
        document.getElementById("qrCodeEl") as HTMLCanvasElement
      )
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      console.log(qrCodeURL);
      let aEl = document.createElement("a");
      aEl.href = qrCodeURL;
      aEl.download = "QR_Code.png";
      document.body.appendChild(aEl);
      aEl.click();
      document.body.removeChild(aEl);
    }
  }, [qrData]);

  const doShow = useCallback((dialog: HTMLDialogElement | null) => {
    if (dialog && !dialog.open) {
      dialog.showModal();
    }
  }, []);

  const addEmail = () => {
    if (!(newMail.length > 0 && newCount > 0 && newCategory.length > 0)) {
      alert("MAIL, ANZAHL & KATEGORIE MÜSSEN ANGEGEBEN WERDEN");
      return;
    }
    let newMailsWithNames: MailNamePair[] = [];
    let includesAlready = false;
    for (let mailWithName of emailsWithNames) {
      newMailsWithNames.push(mailWithName);
      if (mailWithName.email === newMail) {
        includesAlready = true;
      }
    }

    if (!includesAlready) {
      newMailsWithNames.push({
        email: newMail,
        name: newName,
        count: newCount,
        category: newCategory,
      });
    }
    setEmailsWithNames(newMailsWithNames);
    setNewMail("");
    setNewName("");
    setNewCount(1);
    setNewCategory("");
  };

  const options = useMemo(() => {
    var options = [];
    options.push({ value: "", title: "Kategorie auswählen..." });
    if (event?.id === "sunside_2025") {
      options.push({ value: "Festival Pass", title: "Festival Pass" });
      options.push({ value: "Ticket Mittwoch", title: "Ticket Mittwoch" });
      options.push({ value: "Ticket Freitag", title: "Ticket Freitag" });
      options.push({ value: "Ticket Samstag", title: "Ticket Samstag" });
    } else {
      options.push({ value: "Vorverkauf", title: "Vorverkaufsticket" });
      if (event?.id === "oktoberfest-2024") {
        options.push({ value: "6er-Tisch", title: "6er Tisch" });
        options.push({ value: "7er-Tisch", title: "7er Tisch" });
        options.push({ value: "8er-Tisch", title: "8er Tisch" });
      }
    }
    return options;
  }, [event?.id]);

  return (
    <>
      {event === undefined && <div>COULD NOT FIND SELECTED EVENT</div>}
      {event && <div>Infos von: {event.name}</div>}
      {displayDialog && (
        <dialog
          ref={doShow}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <p>Ticket von {resendColumn?.name} erneut versenden</p>

          <div>
            <label>AN:</label>
            <input
              type={"email"}
              placeholder={"Email"}
              value={resendMail}
              onChange={(event) => setResendMail(event.target.value)}
              style={{ width: "100%" }}
            ></input>
          </div>
          <br />
          <button
            style={{ marginBottom: "1rem" }}
            disabled={isSubmitting}
            onClick={() => {
              setIsSubmitting(true);
              resendQrCode();
            }}
          >
            Ticket erneut senden
          </button>
          <button
            disabled={isSubmitting}
            onClick={() => {
              setIsSubmitting(false);
              setDisplayDialog(false);
              setResendColumn(undefined);
            }}
          >
            Cancel
          </button>
        </dialog>
      )}

      <div
        className={"eventAdderWrapper"}
        style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
      >
        <div className={"qrCodeAddWrapper"}>
          <div>
            <label>Email</label>
            <input
              id={"email"}
              value={newMail}
              onChange={(event) => setNewMail(event.target.value)}
              placeholder={"email"}
            ></input>
          </div>
          {/*<div>
            <label>Name</label>
            <input
                value={newName}
                onChange={(event) => setNewName(event.target.value)}
                placeholder={"name"}
            ></input>
          </div>*/}
          <div>
            <label>Anzahl</label>
            <input
              type={"number"}
              value={newCount}
              onChange={(event) => setNewCount(parseInt(event.target.value))}
              placeholder={"Anzahl"}
            ></input>
          </div>
          <div>
            <label>Kategorie</label>
            <select
              value={newCategory}
              onChange={(event) => setNewCategory(event.target.value)}
            >
              {options.map((option) => (
                <option value={option.value}>{option.title}</option>
              ))}
            </select>
          </div>
          <button onClick={addEmail}>Email hinzufügen</button>
        </div>
        <div>
          {emailList.map((value, index) => (
            <div className={"qrCodeListEntry"}>
              <p>
                {value.name}: {value.email}: {value.count}; Kategorie:{" "}
                {value.category} Ticket(s)
              </p>
              <button onClick={(event) => removeMail(value.email)}>
                Entfernen
              </button>
            </div>
          ))}
        </div>
        <button
          onClick={sendQRCodes}
          className={"qrCodeSendButton"}
          disabled={isSubmitting || emailList.length === 0}
        >
          QR CODES SENDEN
        </button>
        <div className={"currentQrCodeWrapper"}>
          <div className={"cardHeader"}>Aktuelle QR CODES</div>
          <table border={1} className={"qrTable"}>
            <thead>
              <tr>
                <th>Email</th>
                <th>Ticket gesendet</th>
                <th>Kategorie</th>
                <th>Tickets übrig</th>
                <th>Versendet am</th>
                <th>Resend Ticket</th>
                <th>DL QR</th>
              </tr>
            </thead>
            <tbody>
              {currentQrCodes.map((row, index) => (
                <tr className={"qrTabelleRow"}>
                  <td>{row.email}</td>
                  <td>{row.ticketsSent}</td>
                  <td>{row.category}</td>
                  <td>{row.ticketsLeft}</td>
                  <td>
                    {DateTime.fromISO(row.timestamp).toFormat(
                      "yyyy-MM-dd HH:mm:ss"
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        setDisplayDialog(true);
                        setResendColumn(row);
                      }}
                    >
                      Resend
                    </button>
                  </td>
                  <td>
                    <button onClick={() => downloadQr(row.id)}>Download</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ display: "none" }}>
            <QRCode id="qrCodeEl" value={qrData} />
          </div>
        </div>
      </div>
    </>
  );
}
